import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Classroom } from 'types/academic/classroomTypes';
import { Teacher } from 'types/academic/teacherTypes';
import { CourseGroupType } from 'types/academic/courseGroupTypeTypes';

export type AcademicSliceState = {
  courseGroupTypes: CourseGroupType[];
  teachers: Teacher[];
  classrooms: Classroom[];
};

const initialState = {
  courseGroupTypes: [],
  teachers: [],
  classrooms: [],
} as AcademicSliceState;

const academicSlice = createSlice({
  name: 'academic',
  initialState: initialState,
  reducers: {
    courseGroupTypesSet(state, action: PayloadAction<CourseGroupType[]>) {
      state.courseGroupTypes = action.payload;
    },
    teachersSet(state, action: PayloadAction<Teacher[]>) {
      state.teachers = action.payload;
    },
    classroomsSet(state, action: PayloadAction<Classroom[]>) {
      state.classrooms = action.payload;
    },
  },
});

export const { courseGroupTypesSet, teachersSet, classroomsSet } = academicSlice.actions;

export default academicSlice.reducer;
