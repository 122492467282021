import React from 'react';
import './index.css';
import './i18n.config';
import 'animate.css/animate.min.css';
import { Provider } from 'react-redux';
import { StatusCodes } from 'http-status-codes';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, ServerError } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import logger from './utils/logger/logger';
import { allPrivateRoutes, LOGIN, URL_QUERY_PARAM_EXPIRED_SESSION } from './utils/routingUtils';
import { reloadPage } from './utils/windowUtils';
import { createRoot } from 'react-dom/client';
import App from 'pages/app/App';

const { REACT_APP_GRAPHQL_PATH: uri } = process.env;

// Sentry.init({
//   dsn: REACT_APP_SENTRY_DSN,
// });

const link = new HttpLink();

/**
 * used to control logout action due to possible multiple parallel graphql requests
 */
let logoutTriggered = false;

/**
 * Reacts to a 401 HTTP error from Apollo Client requests and redirects to the Registration page on the very first encountered 401
 */
const logoutLink = onError(({ networkError }: ErrorResponse) => {
  try {
    const error = networkError as ServerError;
    const loginLocationWithExpired = `/${LOGIN}?${URL_QUERY_PARAM_EXPIRED_SESSION}`;
    const locationPathname = location.pathname.substring(1); // remove / symbols

    if (
      error.statusCode === StatusCodes.UNAUTHORIZED &&
      allPrivateRoutes.includes(locationPathname) &&
      !logoutTriggered
    ) {
      logoutTriggered = true;

      // update the history and location and then refresh the page, only in such way normal redirect works
      history.replaceState(null, '', loginLocationWithExpired);
      location.hash = loginLocationWithExpired;
      reloadPage();
    }
  } catch (e) {
    logger.error(`logoutLink error: ${e}`);
  }
});

const client = new ApolloClient({
  uri,
  credentials: 'include',
  link: logoutLink.concat(link),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);
root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
