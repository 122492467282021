import React, { ReactElement, ReactNode } from 'react';
import { useMediaQuery, MediaQueryMatchers } from 'react-responsive';

export const BREAKPOINTS = {
  //   XS: 360,
  SM: 575,
  //   MD: 768,
  LG: 991,
  XL: 1200,
  XXL: 1400,
  XXXL: 1600,
  XXXXL: 1800,
  XXXXXL: 2000,
  XXXXXXL: 2200,
} as const;

type MediaQueryProps = {
  children: ReactNode;
};

export const isTablet = (width: number) => width > BREAKPOINTS.SM && width <= BREAKPOINTS.LG; // from "sm" to "lg": 576-991
export const isDesktop = (width: number) => width > BREAKPOINTS.LG; // from "lg": >992
export const isLargeDesktop = (width: number) => width > BREAKPOINTS.XL; // from "xl": >1200
export const isExtraLargeDesktop = (width: number) => width > BREAKPOINTS.XXL; // from "xxl": >1400
export const isExtraExtraLargeDesktop = (width: number) => width > BREAKPOINTS.XXXL; // from "xxxl": >1600

// Desktop
export const Desktop: React.FC<MediaQueryProps> = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: BREAKPOINTS.LG } as MediaQueryMatchers); // from "lg": >992
  return isDesktop ? (children as ReactElement) : null;
};

export const LargeDesktop: React.FC<MediaQueryProps> = ({ children }) => {
  const isLargeDesktop = useMediaQuery({ minWidth: BREAKPOINTS.XL } as MediaQueryMatchers); // from "xl": >1200
  return isLargeDesktop ? (children as ReactElement) : null;
};

export const ExtraLargeDesktop: React.FC<MediaQueryProps> = ({ children }) => {
  const isExtraLargeDesktop = useMediaQuery({ minWidth: BREAKPOINTS.XXL } as MediaQueryMatchers); // from "xxl": >1400
  return isExtraLargeDesktop ? (children as ReactElement) : null;
};

// Tablet
export const Tablet: React.FC<MediaQueryProps> = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: BREAKPOINTS.SM + 1, maxWidth: BREAKPOINTS.LG } as MediaQueryMatchers); // from "sm" to "lg": 576-991
  return isTablet ? (children as ReactElement) : null;
};

export const TabletAndDesktop: React.FC<MediaQueryProps> = ({ children }) => {
  const isTabletAndDesktop = useMediaQuery({ minWidth: BREAKPOINTS.SM + 1 } as MediaQueryMatchers); // from "sm": >576
  return isTabletAndDesktop ? (children as ReactElement) : null;
};

// Mobile
export const Mobile: React.FC<MediaQueryProps> = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINTS.SM } as MediaQueryMatchers); // up to "sm": <575
  return isMobile ? (children as ReactElement) : null;
};

export const MobileAndTablet: React.FC<MediaQueryProps> = ({ children }) => {
  const isMobileAndTablet = useMediaQuery({ maxWdth: BREAKPOINTS.LG } as MediaQueryMatchers); // up to "lg": <991
  return isMobileAndTablet ? (children as ReactElement) : null;
};
