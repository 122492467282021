import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries';

export const holidayFragments = {
  holidays: gql`
    fragment Holidays on Holidays {
      items {
        ...Holiday
      }
      paging {
        ...Paging
      }
    }
  `,
  holiday: gql`
    fragment Holiday on Holiday {
      id
      date
      name

      createdDate
      lastModifiedDate
    }
  `,
};

export const getHoliday = gql`
  query getHoliday($id: String!) {
    getHoliday(id: $id) {
      ...Holiday
    }
  }
  ${holidayFragments.holiday}
`;

export const getHolidays = gql`
  query ($paging: SearchPaging, $sorting: Sorting) {
    getHolidays(paging: $paging, sorting: $sorting) {
      ...Holidays
    }
  }
  ${holidayFragments.holidays}
  ${holidayFragments.holiday}
  ${fragments.paging}
`;
