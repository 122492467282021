import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReceiptTemplate } from 'types/marketing/receiptTemplateTypes';
import { FollowupTemplate } from 'types/marketing/followupTemplateTypes';

export type MarketingSliceState = {
  receiptTemplates: ReceiptTemplate[];
  followupTemplates: FollowupTemplate[];
};

const initialState = {
  receiptTemplates: [],
  followupTemplates: [],
} as MarketingSliceState;

const marketingSlice = createSlice({
  name: 'marketing',
  initialState: initialState,
  reducers: {
    receiptTemplatesSet(state, action: PayloadAction<ReceiptTemplate[]>) {
      state.receiptTemplates = action.payload;
    },
    followupTemplatesSet(state, action: PayloadAction<FollowupTemplate[]>) {
      state.followupTemplates = action.payload;
    },
  },
});

export const { receiptTemplatesSet, followupTemplatesSet } = marketingSlice.actions;

export default marketingSlice.reducer;
