import { Rule } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import { TFunction } from 'react-i18next';
import { emailRegex } from 'utils/stringUtils';
import { passwordMaxLength, passwordMinLength } from 'utils/userUtils';

export const emailRule = (t: TFunction) =>
  ({
    pattern: emailRegex,
    message: t('validation_rules.email_regex'),
  } as Rule);

export const passwordRule = (t: TFunction) => [
  {
    min: passwordMinLength,
    message: t('validation_rules.password_min', { length: passwordMinLength }),
  } as Rule,
  {
    max: passwordMaxLength,
    message: t('validation_rules.password_max', { length: passwordMaxLength }),
  } as Rule,
];

export const confirmPasswordRule =
  (t: TFunction) =>
  ({ getFieldValue }: NamePath) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validator(_: any, value: string) {
      const passwordFieldValue = getFieldValue('password');
      return value && value !== passwordFieldValue
        ? Promise.reject(t('validation_rules.password_mismatch'))
        : Promise.resolve(null);
    },
  });

export const phoneNumberRule = (t: TFunction) =>
  ({
    pattern: /^\+(?:\d{1,4})?[-. \(\)/\d]{1,15}$/,
    message: t('validation_rules.phone_number_regex'),
  } as Rule);
export const engMoneyRule = (t: TFunction) =>
  ({
    pattern: /[.]+[0-9]{1,2}$/,
    message: t('validation_rules.tuition_cost_regex'),
  } as Rule);
export const espMoneyRule = (t: TFunction) =>
  ({
    pattern: /[,]+[0-9]{1,2}$/,
    message: t('validation_rules.tuition_cost_regex'),
  } as Rule);

export const customRules = {
  required: (fieldName: string, t: TFunction): Rule => ({
    required: true,
    message: t('validation_rules.required', { name: fieldName }),
  }),
  engMoneyRule,
  espMoneyRule,
  emailRule,
  passwordRule,
  confirmPasswordRule,
  phoneNumberRule,
};
