import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries';

export const classroomFragments = {
  classroom: gql`
    fragment Classroom on Classroom {
      id
      name
      building
      floor
      recommendedStudentsNumber
      maxStudentsNumber

      disabled
      deleted

      createdDate
      lastModifiedDate
    }
  `,
  classrooms: gql`
    fragment Classrooms on Classrooms {
      items {
        ...Classroom
      }
      paging {
        ...Paging
      }
    }
  `,
};

export const getClassroom = gql`
  query getClassroom($id: String!) {
    getClassroom(id: $id) {
      ...Classroom
    }
  }
  ${classroomFragments.classroom}
`;

export const getClassrooms = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: ClassroomSearchCriteria) {
    getClassrooms(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...Classrooms
    }
  }
  ${classroomFragments.classroom}
  ${classroomFragments.classrooms}
  ${fragments.paging}
`;
