import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TablesConfig } from 'types/config/configTypes';
import { Agency } from 'types/config/agencyTypes';
import { CourseType } from 'types/config/course/courseTypes';
import { PriceYear } from 'types/config/priceYearTypes';
import { PriceConfig } from 'types/config/priceConfigTypes';
import { OfficeManager } from 'types/config/officeManagerTypes';
import { User } from 'types/types';
import { Holiday } from 'types/config/holidayTypes';

export type ConfigSliceState = {
  tablesConfig: TablesConfig | null;
  users: User[];
  officeManagers: OfficeManager[];
  agencies: Agency[];
  courseTypes: CourseType[];
  priceYears: PriceYear[];
  priceConfigs: PriceConfig[];
  holidays: Holiday[];
};

const initialState = {
  tablesConfig: null,
  users: [],
  officeManagers: [],
  agencies: [],
  courseTypes: [],
  priceYears: [],
  priceConfigs: [],
  holidays: [],
} as ConfigSliceState;

const configSlice = createSlice({
  name: 'config',
  initialState: initialState,
  reducers: {
    tablesConfigSet(state, action: PayloadAction<TablesConfig>) {
      state.tablesConfig = action.payload;
    },
    usersSet(state, action: PayloadAction<User[]>) {
      state.users = action.payload;
    },
    agenciesSet(state, action: PayloadAction<Agency[]>) {
      state.agencies = action.payload;
    },
    officeManagersSet(state, action: PayloadAction<OfficeManager[]>) {
      state.officeManagers = action.payload;
    },
    courseTypesSet(state, action: PayloadAction<CourseType[]>) {
      state.courseTypes = action.payload;
    },
    priceYearsSet(state, action: PayloadAction<PriceYear[]>) {
      state.priceYears = action.payload;
    },
    priceConfigsSet(state, action: PayloadAction<PriceConfig[]>) {
      state.priceConfigs = action.payload;
    },
    holidaysSet(state, action: PayloadAction<Holiday[]>) {
      state.holidays = action.payload;
    },
  },
});

export const {
  tablesConfigSet,
  usersSet,
  agenciesSet,
  officeManagersSet,
  courseTypesSet,
  priceYearsSet,
  priceConfigsSet,
  holidaysSet,
} = configSlice.actions;

export default configSlice.reducer;
