import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccommodationBookingFoodType } from 'types/sales/accommodation/accommodationBookingFoodTypes';
import { AccommodationBookingType } from 'types/sales/accommodation/accommodationBookingTypeTypes';
import { AccommodationBookingRoomType } from 'types/sales/accommodation/accommodationRoomTypes';

export type SalesSliceState = {
  accommodationBookingTypes: AccommodationBookingType[];
  accommodationBookingRoomTypes: AccommodationBookingRoomType[];
  accommodationBookingFoodTypes: AccommodationBookingFoodType[];
};

const initialState = {
  accommodationBookingTypes: [],
  accommodationBookingRoomTypes: [],
  accommodationBookingFoodTypes: [],
} as SalesSliceState;

const salesSlice = createSlice({
  name: 'sales',
  initialState: initialState,
  reducers: {
    accommodationBookingTypesSet(state, action: PayloadAction<AccommodationBookingType[]>) {
      state.accommodationBookingTypes = action.payload;
    },
    accommodationBookingRoomTypesSet(state, action: PayloadAction<AccommodationBookingRoomType[]>) {
      state.accommodationBookingRoomTypes = action.payload;
    },
    accommodationBookingFoodTypesSet(state, action: PayloadAction<AccommodationBookingFoodType[]>) {
      state.accommodationBookingFoodTypes = action.payload;
    },
  },
});

export const { accommodationBookingTypesSet, accommodationBookingRoomTypesSet, accommodationBookingFoodTypesSet } =
  salesSlice.actions;

export default salesSlice.reducer;
