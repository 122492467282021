import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import authSliceReducer, { AuthSliceState } from 'slices/authSlice';
import { createTrackedSelector } from 'react-tracked';
import { useSelector } from 'react-redux';
import configSliceReducer, { ConfigSliceState } from 'slices/configSlice';
import academicSliceReducer, { AcademicSliceState } from 'slices/academicSlice';
import accommodationSliceReducer, { AccommodationSliceState } from 'slices/accommodationSlice';
import marketingSliceReducer, { MarketingSliceState } from 'slices/marketingSlice';
import salesSliceReducer, { SalesSliceState } from 'slices/salesSlice';

const allReducers = {
  academic: academicSliceReducer,
  accommodation: accommodationSliceReducer,
  auth: authSliceReducer,
  config: configSliceReducer,
  marketing: marketingSliceReducer,
  sales: salesSliceReducer,
};

export const useTrackedSelector = createTrackedSelector<{
  auth: AuthSliceState;
  academic: AcademicSliceState;
  accommodation: AccommodationSliceState;
  config: ConfigSliceState;
  marketing: MarketingSliceState;
  sales: SalesSliceState;
}>(useSelector);

const combinedReducer = combineReducers(allReducers);

export const RESET = { type: 'reset' };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: any) => {
  if (action.type === RESET.type) {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
});
