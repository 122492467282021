import { useTranslation } from 'react-i18next';
import moment, { LocaleSpecification, Moment } from 'moment';
import logger from 'utils/logger/logger';
import { DatePeriodType, LanguageCode } from 'types/types.d';
import { TFunction } from 'react-i18next';

export const defaultDateFormat = 'dd.mm.yyyy'; // todo check it before using
export const iso8601DateFormat = 'YYYY-MM-DD';
export const defaultTimeFormat = 'HH:mm';

export const millisecondsInDay = 24 * 60 * 60 * 1000; // Represents the number of seconds in a day
export const daysInWeek = 7;
export const daysInYear = 365.24;

/**
 * This is a place to define any Moment lib customisations.
 */
export const useCustomisedMomentLocales = (): void => {
  const { i18n } = useTranslation();

  [LanguageCode.ES].forEach((language) => {
    const data = i18n.getDataByLanguage(language);

    if (data && data.translation) {
      const dateAndTime = JSON.parse(JSON.stringify(data.translation['date_and_time']));
      moment.defineLocale(LanguageCode.ES, {
        monthsShort: dateAndTime.months.short_all.split(','),
        months: dateAndTime.months.long_all.split(','),
        weekdaysMin: dateAndTime.week_days.min_all.split(','),
        weekdaysShort: dateAndTime.week_days.short_all.split(','),
        weekdays: dateAndTime.week_days.long_all.split(','),
      } as LocaleSpecification);
    } else {
      logger.error(`${language} i18n is required`);
    }
  });
};

export interface LocalisedDateTimeValues {
  [key: string]: string | string[] | number;
}

/**
 * Returns internationalised labels for date and time fields
 */
export const useLocalisedDateTimeValues = (): LocalisedDateTimeValues => {
  const { t } = useTranslation();

  return {
    format: t('DATE_AND_TIME.DATE_FORMAT'),
    formatHuman: t('DATE_AND_TIME.DATE_FORMAT_HUMAN'),
    separator: ' - ',
    applyLabel: t('COMMON.APPLY'),
    cancelLabel: t('COMMON.CANCEL'),
    weekLabel: t('DATE_AND_TIME.WEEK_LABEL'),
    firstDay: 1,
    daysOfWeekShort: [
      t('DATE_AND_TIME.WEEK_DAYS.SHORT.SUNDAY'),
      t('DATE_AND_TIME.WEEK_DAYS.SHORT.MONDAY'),
      t('DATE_AND_TIME.WEEK_DAYS.SHORT.TUESDAY'),
      t('DATE_AND_TIME.WEEK_DAYS.SHORT.WEDNESDAY'),
      t('DATE_AND_TIME.WEEK_DAYS.SHORT.THURSDAY'),
      t('DATE_AND_TIME.WEEK_DAYS.SHORT.FRIDAY'),
      t('DATE_AND_TIME.WEEK_DAYS.SHORT.SATURDAY'),
    ],
    daysOfWeekLong: [
      t('DATE_AND_TIME.WEEK_DAYS.LONG.SUNDAY'),
      t('DATE_AND_TIME.WEEK_DAYS.LONG.MONDAY'),
      t('DATE_AND_TIME.WEEK_DAYS.LONG.TUESDAY'),
      t('DATE_AND_TIME.WEEK_DAYS.LONG.WEDNESDAY'),
      t('DATE_AND_TIME.WEEK_DAYS.LONG.THURSDAY'),
      t('DATE_AND_TIME.WEEK_DAYS.LONG.FRIDAY'),
      t('DATE_AND_TIME.WEEK_DAYS.LONG.SATURDAY'),
    ],
    // NOTE: don't change "daysOfWeek" word to anything cause it's needed this way in the Date Range Picker
    daysOfWeek: [
      t('DATE_AND_TIME.WEEK_DAYS.MIN.SUNDAY'),
      t('DATE_AND_TIME.WEEK_DAYS.MIN.MONDAY'),
      t('DATE_AND_TIME.WEEK_DAYS.MIN.TUESDAY'),
      t('DATE_AND_TIME.WEEK_DAYS.MIN.WEDNESDAY'),
      t('DATE_AND_TIME.WEEK_DAYS.MIN.THURSDAY'),
      t('DATE_AND_TIME.WEEK_DAYS.MIN.FRIDAY'),
      t('DATE_AND_TIME.WEEK_DAYS.MIN.SATURDAY'),
    ],
    monthNamesShort: [
      t('DATE_AND_TIME.MONTHS.SHORT.JANUARY'),
      t('DATE_AND_TIME.MONTHS.SHORT.FEBRUARY'),
      t('DATE_AND_TIME.MONTHS.SHORT.MARCH'),
      t('DATE_AND_TIME.MONTHS.SHORT.APRIL'),
      t('DATE_AND_TIME.MONTHS.SHORT.MAY'),
      t('DATE_AND_TIME.MONTHS.SHORT.JUNE'),
      t('DATE_AND_TIME.MONTHS.SHORT.JULY'),
      t('DATE_AND_TIME.MONTHS.SHORT.AUGUST'),
      t('DATE_AND_TIME.MONTHS.SHORT.SEPTEMBER'),
      t('DATE_AND_TIME.MONTHS.SHORT.OCTOBER'),
      t('DATE_AND_TIME.MONTHS.SHORT.NOVEMBER'),
      t('DATE_AND_TIME.MONTHS.SHORT.DECEMBER'),
    ],
    // NOTE: don't change "monthNames" word to anything cause it's needed this way in the Date Range Picker
    monthNames: [
      t('DATE_AND_TIME.MONTHS.LONG.JANUARY'),
      t('DATE_AND_TIME.MONTHS.LONG.FEBRUARY'),
      t('DATE_AND_TIME.MONTHS.LONG.MARCH'),
      t('DATE_AND_TIME.MONTHS.LONG.APRIL'),
      t('DATE_AND_TIME.MONTHS.LONG.MAY'),
      t('DATE_AND_TIME.MONTHS.LONG.JUNE'),
      t('DATE_AND_TIME.MONTHS.LONG.JULY'),
      t('DATE_AND_TIME.MONTHS.LONG.AUGUST'),
      t('DATE_AND_TIME.MONTHS.LONG.SEPTEMBER'),
      t('DATE_AND_TIME.MONTHS.LONG.OCTOBER'),
      t('DATE_AND_TIME.MONTHS.LONG.NOVEMBER'),
      t('DATE_AND_TIME.MONTHS.LONG.DECEMBER'),
    ],
  } as LocalisedDateTimeValues;
};

interface PresetDate {
  label: string;
  value: [Moment, Moment];
}

export const getRangePickerPresets = (t: TFunction): PresetDate[] => [
  { label: t('date_and_time.today'), value: [moment().startOf('day'), moment().endOf('day')] },
  {
    label: t('date_and_time.yesterday'),
    value: [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')],
  },
  {
    label: t('date_and_time.this_week'),
    value: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
  },
  {
    label: t('date_and_time.this_month'),
    value: [moment().startOf('month'), moment().endOf('month')],
  },
  {
    label: t('date_and_time.this_quarter'),
    value: [moment().startOf('quarter'), moment().endOf('quarter')],
  },
  {
    label: t('date_and_time.this_year'),
    value: [moment().startOf('year'), moment().endOf('year')],
  },
  {
    label: t('date_and_time.last_month'),
    value: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  },
  {
    label: t('date_and_time.last_quarter'),
    value: [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')],
  },
  {
    label: t('date_and_time.last_year'),
    value: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
  },
  {
    label: t('date_and_time.last_30_days'),
    value: [moment().subtract(30, 'day'), moment()],
  },
  {
    label: t('date_and_time.last_3_months'),
    value: [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  },
  {
    label: t('date_and_time.last_3_quarters'),
    value: [moment().subtract(3, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')],
  },
  {
    label: t('date_and_time.year_to_date'),
    value: [moment().startOf('year'), moment()],
  },
];

/**
 * @return true if the periods intersect at least by one day including borders
 */
export const periodsIntersectIncludingBorders = ({
  period1Start,
  period1End,
  period2Start,
  period2End,
}: {
  period1Start: Moment;
  period1End: Moment;
  period2Start: Moment;
  period2End: Moment;
}): boolean => {
  return (
    (period1Start.isSameOrBefore(period2Start) && period1End.isSameOrAfter(period2Start)) ||
    (period1Start.isSameOrAfter(period2Start) && period1Start.isSameOrBefore(period2End))
  );
};

/**
 * @return a single string label describing a period
 */
export const getHumanReadablePeriodFormat = (periodType: DatePeriodType): string => {
  switch (periodType) {
    case DatePeriodType.DAY:
      return 'DD MMM YYYY';
    case DatePeriodType.WEEK:
      return '[W]W/YYYY';
    case DatePeriodType.MONTH:
      return 'MM/YYYY';
    case DatePeriodType.QUARTER:
      return '[Q]Q/YYYY';
    case DatePeriodType.YEAR:
      return 'YYYY';
  }
};

export const getWeekday = (orderNumber: number, t: TFunction, type: 'min' | 'short' | 'long'): string => {
  let day = '';
  switch (orderNumber) {
    case 1:
      day = 'monday';
      break;
    case 2:
      day = 'tuesday';
      break;
    case 3:
      day = 'wednesday';
      break;
    case 4:
      day = 'thursday';
      break;
    case 5:
      day = 'friday';
      break;
    case 6:
      day = 'saturday';
      break;
    case 7:
      day = 'sunday';
      break;
    default:
      day = '';
  }
  return !!day.length ? t(`date_and_time.week_days.${type}.${day}`) : '';
};
