import { gql } from '@apollo/client';

export const configFragments = {
  tablesConfig: gql`
    fragment TablesConfig on TablesConfig {
      leadsColumns
      reservationsColumns
      paymentsColumns
      invoicesColumns
      cancellationsColumns

      agenciesColumns
      coursePricesColumns
      accommodationPricesColumns
    }
  `,
};

export const getTablesConfig = gql`
  query getTablesConfig {
    getTablesConfig {
      ...TablesConfig
    }
  }
  ${configFragments.tablesConfig}
`;
