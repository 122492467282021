import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries';

export const platformFragments = {
  discount: gql`
    fragment Discount on Discount {
      type
      amount
    }
  `,
  file: gql`
    fragment File on File {
      name
      content
    }
  `,
  timePeriod: gql`
    fragment TimePeriod on TimePeriod {
      start
      end
    }
  `,
  platformMetrics: gql`
    fragment PlatformMetrics on PlatformMetrics {
      date

      profiles
      registrationsYesterday
      registrationsLastWeek
      registrationsLastMonth

      usersWithConfirmedEmail
      deletedProfiles

      dau
      wau
      mau
      logins
      loginsYesterday
      loginsLastWeek
      loginsLastMonth

      usersWithFreemiumPlan
      usersWithoutPlan
      usersWithPaidPlan
      usersWithMonthlySubscription
      usersWithYearlySubscription
      cancelledPlans

      usersWithTwoFA
      usersWithEn
      usersWithDe

      usersWithUserRole
      usersWithAdminRole

      socialRegistrations
    }
  `,
};

export const getPlatformMetrics = gql`
  query ($fromDate: String, $toDate: String) {
    getPlatformMetrics(fromDate: $fromDate, toDate: $toDate) {
      ...PlatformMetrics
    }
  }
  ${platformFragments.platformMetrics}
`;

export const getBookingsAnalytics = gql`
  query ($searchCriteria: BookingSearchCriteria, $datePeriodType: DatePeriodType!) {
    getBookingsAnalytics(searchCriteria: $searchCriteria, datePeriodType: $datePeriodType) {
      ...Pair
    }
  }
  ${fragments.pair}
`;

export const getMarketingConversionsAnalytics = gql`
  query ($searchCriteria: MarketingConversionSearchCriteria, $datePeriodType: DatePeriodType!) {
    getMarketingConversionsAnalytics(searchCriteria: $searchCriteria, datePeriodType: $datePeriodType) {
      ...Pair
    }
  }
  ${fragments.pair}
`;

export const getPaymentsAnalytics = gql`
  query ($searchCriteria: PaymentSearchCriteria, $datePeriodType: DatePeriodType!) {
    getPaymentsAnalytics(searchCriteria: $searchCriteria, datePeriodType: $datePeriodType) {
      ...Triple
    }
  }
  ${fragments.triple}
`;
