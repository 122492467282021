import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries';

export const accommodationBookingRoomTypeFragments = {
  accommodationBookingRoomTypes: gql`
    fragment AccommodationBookingRoomTypes on AccommodationBookingRoomTypes {
      items {
        ...AccommodationBookingRoomType
      }
      paging {
        ...Paging
      }
    }
  `,
  accommodationBookingRoomType: gql`
    fragment AccommodationBookingRoomType on AccommodationBookingRoomType {
      id
      name
      nameEn
      translations {
        ...Translation
      }

      createdDate
      lastModifiedDate
    }
  `,
};

export const getAccommodationBookingRoomType = gql`
  query getAccommodationBookingRoomType($id: Int!) {
    getAccommodationBookingRoomType(id: $id) {
      ...AccommodationBookingRoomType
    }
  }
  ${accommodationBookingRoomTypeFragments.accommodationBookingRoomType}
  ${fragments.translation}
`;

export const getAccommodationBookingRoomTypes = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: AccommodationBookingRoomTypeSearchCriteria) {
    getAccommodationBookingRoomTypes(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...AccommodationBookingRoomTypes
    }
  }
  ${accommodationBookingRoomTypeFragments.accommodationBookingRoomType}
  ${accommodationBookingRoomTypeFragments.accommodationBookingRoomTypes}
  ${fragments.translation}
  ${fragments.paging}
`;
