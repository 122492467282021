import { gql } from '@apollo/client';

export const priceYearFragments = {
  priceYear: gql`
    fragment PriceYear on PriceYear {
      id
      name
      active
    }
  `,
};

export const getPriceYears = gql`
  query {
    getPriceYears {
      ...PriceYear
    }
  }
  ${priceYearFragments.priceYear}
`;
