import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from './assets/i18n/en/translation.json';
import translationES from './assets/i18n/es/translation.json';
import { LanguageCode } from 'types/types.d';

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};

const fallbackLng = [LanguageCode.EN];
const availableLanguages = [LanguageCode.EN, LanguageCode.ES];

i18n
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
    debug: process.env.NODE_ENV === 'development',
    supportedLngs: availableLanguages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // default detection seems to work like we need it, i.e. the language is stored and read via localStorage 'i18nextLng'
    // detection: {
    // order and from where user language should be detected
    // order: ['navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],

    // keys or params to lookup language from
    // lookupQuerystring: 'lng',
    // lookupCookie: 'i18next',
    // lookupLocalStorage: 'i18nextLng',
    // lookupFromPathIndex: 0,
    // lookupFromSubdomainIndex: 0,

    // cache user language on
    // caches: ['localStorage', 'cookie'],
    // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    // cookieMinutes: 10,
    // cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    // htmlTag: document.documentElement
    // },
  });

export default i18n;
