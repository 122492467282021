import React, { useCallback, useContext, useState } from 'react';
import * as S from './Registration.styles';
import { useNavigate } from 'react-router-dom';
import { LOGIN, LOGIN_TWO_FA } from 'utils/routingUtils';
import { UserEventType } from 'types/types.d';
import useUserTracking from 'hooks/useUserTracking';
import { login, LoginResult, loginWithGoogle } from 'services/auth.service';
import { Button, Checkbox, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import {
  CheckSquareOutlined,
  FacebookOutlined,
  GoogleOutlined,
  LockOutlined,
  MailOutlined,
  TwitterOutlined,
} from '@ant-design/icons';
import Link from 'antd/es/typography/Link';
import { BREAKPOINTS } from 'components/Breakpoints';
import { UserContext, UserContextProps } from 'contextProviders/UserProvider';
import logger from 'utils/logger/logger';
import { passwordMinLength } from 'utils/userUtils';
import { customRules } from 'utils/rulesUtils';

export interface RegistrationLocationState {
  email?: string;
  password?: string;
}

/**
 * Shows login form for a user to provide basic authentication credentials.
 * Registration is possible with email/password or with Google.
 *
 * If logging is with Google a redirect to our OAuth2 endpoint happens where communication of our BE and Google happens,
 * in case of a successful login a redirect to this component with a short-lived refresh token happens.
 * The token is stored in the needed cookie and the page is refreshed with an automatic tokens refresh happening in the BE.
 */

const Registration = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const trackUserEvent = useUserTracking();
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINTS.SM });

  const { fetchUser } = useContext(UserContext) as UserContextProps;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleSubmit = useCallback((): void => {
    login('user@sample.com', '12345678', true)
      .then((result: LoginResult) => {
        if (result && result.loginOk) {
          if (result.twoFA) {
            // send user to the 2FA second step
            navigate(`/${LOGIN_TWO_FA}`, { state: { rememberMe: true } });
          } else {
            trackUserEvent(UserEventType.USER_LOGGED_IN);
            fetchUser();
          }
        } else {
          console.log('could not log in'); // todo replace with notification
          logger.error('could not log in');
        }
      })
      .catch((e) => {
        console.log('could not log in'); // todo replace with notification
        logger.error(`could not log in: ${e}`);
      });
  }, [fetchUser, navigate, trackUserEvent]);

  const handleClickGoogle = useCallback(() => loginWithGoogle(`/${LOGIN}`), []);
  const handleClickTwitter = useCallback(() => loginWithGoogle(`/${LOGIN}`), []);
  const handleClickFacebook = useCallback(() => loginWithGoogle(`/${LOGIN}`), []);

  const handleRegistration = useCallback(
    ({ email, password }: { email: string; password: string }) =>
      navigate(`/${LOGIN}`, {
        state: {
          email,
          password,
        } as RegistrationLocationState,
      }),
    [navigate],
  );

  return (
    <S.RegistrationCard bodyStyle={{ padding: 0 }}>
      <S.TitleAndLink>
        <S.HeaderTitle>{t('public.registration.title')}</S.HeaderTitle>
        <S.HeaderLink onClick={() => handleRegistration({ email: 'user@sample.com', password: '12345678' })}>
          {t('public.registration.account')}
        </S.HeaderLink>
      </S.TitleAndLink>

      <Form name="registration" layout="vertical" autoComplete="true" onFinish={handleSubmit}>
        <S.FormItemRegistration
          name="email"
          required={false}
          label={isMobile ? null : t('public.registration.email_address')}
          rules={[customRules.required(t('public.login.email_address'), t), customRules.emailRule(t)]}
        >
          <Input
            placeholder={t('common.input')}
            prefix={<MailOutlined style={{ opacity: 0.45, marginRight: '.25rem' }} />}
          />
        </S.FormItemRegistration>

        <S.FormItemRegistration
          name="password"
          required={false}
          label={isMobile ? null : t('public.registration.password')}
          rules={[customRules.required(t('public.login.password'), t), ...customRules.passwordRule(t)]}
        >
          <Input.Password
            placeholder={t('common.input', { length: passwordMinLength })}
            prefix={<LockOutlined style={{ opacity: 0.45, marginRight: '.25rem' }} />}
          />
        </S.FormItemRegistration>

        <S.FormItemRegistration
          name="confirmPassword"
          required={false}
          label={isMobile ? null : t('public.registration.confirm_password')}
          dependencies={['password']}
          rules={[customRules.required(t('public.login.confirm_password'), t), customRules.confirmPasswordRule(t)]}
        >
          <Input.Password
            placeholder={t('common.input', { length: passwordMinLength })}
            prefix={<CheckSquareOutlined style={{ opacity: 0.45, marginRight: '.25rem' }} />}
          />
        </S.FormItemRegistration>

        <S.TermsAccepted>
          <S.FormItemRegistration name="accept">
            <Checkbox checked={termsAccepted} onChange={() => setTermsAccepted((prev) => !prev)}>
              {t('public.registration.accept')}
            </Checkbox>
          </S.FormItemRegistration>
          <Link>{t('public.registration.terms_and_conditions')}</Link>
        </S.TermsAccepted>

        <Button type="primary" block htmlType="submit" disabled={!termsAccepted} loading={loading}>
          {t('public.registration.btn_sign_up')}
        </Button>

        <S.RegistrationDivider plain>{t('public.registration.sign_up_with')}</S.RegistrationDivider>

        <S.BtnsSocialNetworks>
          <S.BtnSocialNetwork
            icon={<GoogleOutlined />}
            block={isMobile ? true : undefined}
            disabled={!termsAccepted}
            onClick={handleClickGoogle}
          >
            {t('public.registration.btn_google')}
          </S.BtnSocialNetwork>
          <S.BtnSocialNetwork
            icon={<TwitterOutlined />}
            disabled={!termsAccepted}
            block={isMobile ? true : undefined}
            onClick={handleClickTwitter}
          >
            {t('public.registration.btn_twitter')}
          </S.BtnSocialNetwork>
          <S.BtnSocialNetwork
            icon={<FacebookOutlined />}
            disabled={!termsAccepted}
            block={isMobile ? true : undefined}
            onClick={handleClickFacebook}
          >
            {t('public.registration.btn_facebook')}
          </S.BtnSocialNetwork>
        </S.BtnsSocialNetworks>
      </Form>
    </S.RegistrationCard>
  );
};

export default Registration;
