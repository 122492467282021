import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries';

export const officeManagerFragments = {
  officeManagers: gql`
    fragment OfficeManagers on OfficeManagers {
      items {
        ...OfficeManager
      }
      paging {
        ...Paging
      }
    }
  `,
  officeManager: gql`
    fragment OfficeManager on OfficeManager {
      id
      name
      language
      deleted

      createdDate
      lastModifiedDate
    }
  `,
};

export const getOfficeManager = gql`
  query getOfficeManager($id: String!) {
    getOfficeManager(id: $id) {
      ...OfficeManager
    }
  }
  ${officeManagerFragments.officeManager}
`;

export const getOfficeManagers = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: OfficeManagerSearchCriteria) {
    getOfficeManagers(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...OfficeManagers
    }
  }
  ${officeManagerFragments.officeManagers}
  ${officeManagerFragments.officeManager}
  ${fragments.paging}
`;
