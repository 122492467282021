import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import logger from 'utils/logger/logger';
import { getDomain, redirectToExternalUrl } from 'utils/windowUtils';
import { URL_QUERY_PARAM_ACTION, URL_QUERY_PARAM_ACTION_LOGIN } from 'utils/routingUtils';
import { ContentType } from 'types/contentTypes';

export interface LoginResult {
  loginOk: boolean;
  twoFA: boolean;
}

/**
 * @return an object indicating if login request was OK and 2FA flow must take place
 */
export const login = (email: string, password: string, rememberMe: boolean): Promise<LoginResult> => {
  return axios
    .post(
      '/api/auth/login',
      {
        email: email,
        password: password,
        rememberMe: rememberMe,
      },
      {
        headers: {
          'Content-Type': ContentType.APPLICATION_JSON,
        },
      },
    )
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return {
          loginOk: true,
          twoFA: response.data.twoFA,
        } as LoginResult;
      } else {
        logger.error(`Login result is not OK: ${response.status}`);
        return {
          loginOk: false,
          twoFA: false,
        } as LoginResult;
      }
    })
    .catch((error) => {
      logger.error(`Login error happened: ${error}`);
      return {
        loginOk: false,
        twoFA: false,
      } as LoginResult;
    });
};

/**
 * Logs in secretly as another user.
 *
 * @return an object indicating if login request was OK
 */
export const secretLogin = (email: string): Promise<boolean> => {
  return axios
    .post(
      '/api/auth/login/secret',
      {
        email: email,
      },
      {
        headers: {
          'Content-Type': ContentType.APPLICATION_JSON,
        },
      },
    )
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Secret login result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch((error) => {
      logger.error(`Secret login error happened: ${error}`);
      return false;
    });
};

/**
 * @return true if 2FA request was OK, false otherwise
 */
export const loginWithTwoFA = (code: string, rememberMe: boolean): Promise<boolean> => {
  return axios
    .post(
      '/api/auth/2fa',
      {
        code: code,
        rememberMe: rememberMe,
      },
      {
        headers: {
          'Content-Type': ContentType.APPLICATION_JSON,
        },
      },
    )
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`2FA login result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch((error) => {
      logger.error(`2FA login error happened: ${error}`);
      return false;
    });
};

/**
 * @return true if 2FA flow was triggered successfully on the BE side
 */
export const triggerTwoFAFlow = (): Promise<boolean> => {
  return axios
    .get('/api/auth/2fa')
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`2FA triggering result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch((error) => {
      logger.error(`triggerTwoFAFlow error happened: ${error}`);
      return false;
    });
};

/**
 * @return true in any case because the actual result is not important to the user
 */
export const logout = (): Promise<boolean> => {
  return axios
    .delete('/api/auth/logout')
    .then(() => {
      return true;
    })
    .catch((error) => {
      logger.error(`Logout error happened: ${error}`);
      return true;
    });
};

/**
 * Sends a request to get new tokens (in cookies) based on the provided on the current token cookie
 * @return true if refresh request was OK, false otherwise
 */
export const refresh = (): Promise<boolean> => {
  return axios
    .post('/api/auth/refresh')
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Refresh result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch((error) => {
      logger.error(`Refresh error happened: ${error}`);
      return false;
    });
};

/**
 * Redirects to our BE OAuth2 endpoint to log in with Google.
 * Defines the BE and FE domains to cover local(locally BE and FE run on different domains via proxying) and not local runtimes.
 */
export const loginWithGoogle = (route: string): void => {
  const feDomain = getDomain();
  let beDomain = feDomain;

  if (!!process.env.REACT_APP_BE_URL) {
    beDomain = process.env.REACT_APP_BE_URL;
  }
  const actionPart = `${URL_QUERY_PARAM_ACTION}=${URL_QUERY_PARAM_ACTION_LOGIN}&`;
  redirectToExternalUrl(`${beDomain}/api/oauth2/authorize/google?${actionPart}redirect_uri=${feDomain}/%23${route}`);
};
