import React, { Suspense, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppBody from 'pages/appBody/AppBody';
import CustomSpin from 'components/customSpin/CustomSpin';
import { ConfigProvider } from 'antd';
import ThemeProviderWrapper from 'contextProviders/ThemeProvider';
import UserProvider, { UserContext, UserContextProps } from 'contextProviders/UserProvider';
import Modal from 'components/modal/Modal';
import ModalProvider from 'contextProviders/ModalProvider';
import { primary } from 'utils/styleUtils';
import enGB from 'antd/locale/en_GB';

/**
 * - Checks currently logged in user by sending a GraphQL request.
 * - URL-provided language is checked and then user-preferred one is checked and set if present.
 *
 * Note: 1) the user's role and 2) whether a user is logged in define which routes are allowed to be accessed
 */
const App = (): JSX.Element => {
  const isStrictModeEnabled = process.env.REACT_APP_STRICT_MODE === 'true';

  const appContent = (
    <Router>
      <ConfigProvider
        // locale={language === LanguageCode.ES ? esES : enGB} do not enable - otherwise the date shifts forward by 1 day
        locale={enGB}
        theme={{
          token: {
            colorPrimary: primary,
            colorLink: primary,
          },
        }}
      >
        <Suspense fallback={<CustomSpin positioningHeight="50vh" />}>
          <UserProvider>
            <ModalProvider>
              <AppComponent />
              <Modal />
            </ModalProvider>
          </UserProvider>
        </Suspense>
      </ConfigProvider>
    </Router>
  );

  return isStrictModeEnabled ? <React.StrictMode>{appContent}</React.StrictMode> : appContent;
};

/**
 * The main reason to separate this component from the upper one is to separate the hooks to fetch user.
 * Otherwise user is fetched every time the language changes and this is creates a cyclic dependency.
 */
const AppComponent = () => {
  const { loading } = useContext(UserContext) as UserContextProps;

  return <ThemeProviderWrapper>{loading ? <CustomSpin positioningHeight="50vh" /> : <AppBody />}</ThemeProviderWrapper>;
};

export default App;
