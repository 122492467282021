import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries';

export const followupTemplateFragments = {
  followupTemplates: gql`
    fragment FollowupTemplates on FollowupTemplates {
      items {
        ...FollowupTemplate
      }
      paging {
        ...Paging
      }
    }
  `,
  followupTemplate: gql`
    fragment FollowupTemplate on FollowupTemplate {
      id
      key

      group
      name

      description
      active

      subjects {
        ...Translation
      }
      subjectsVisa {
        ...Translation
      }
      bodies {
        ...Translation
      }
      bodiesVisa {
        ...Translation
      }

      deleted

      createdDate
      lastModifiedDate
    }
  `,
};

export const getFollowupTemplate = gql`
  query getFollowupTemplate($id: Int!) {
    getFollowupTemplate(id: $id) {
      ...FollowupTemplate
    }
  }
  ${followupTemplateFragments.followupTemplate}
  ${fragments.translation}
`;

export const getFollowupTemplateLastModifiedDate = gql`
  query getFollowupTemplateLastModifiedDate($id: Int!) {
    getFollowupTemplateLastModifiedDate(id: $id)
  }
`;

export const getFollowupTemplateByKey = gql`
  query getFollowupTemplateByKey($key: String!) {
    getFollowupTemplateByKey(key: $key) {
      ...FollowupTemplate
    }
  }
  ${followupTemplateFragments.followupTemplate}
  ${fragments.translation}
`;

export const getFollowupTemplates = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: FollowupTemplateSearchCriteria) {
    getFollowupTemplates(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...FollowupTemplates
    }
  }
  ${followupTemplateFragments.followupTemplates}
  ${followupTemplateFragments.followupTemplate}
  ${fragments.translation}
  ${fragments.paging}
`;
