import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LOGIN, REGISTER } from 'utils/routingUtils';
import Login from '../login/Login';
import Registration from '../registration/Registration';
import * as S from './PublicHome.styles';

// import LoginTwoFA from '../loginTwoFA';
// import PasswordRecover from '../passwordRecover';
// import PasswordReset from '../passwordReset';
// import RequestCode from '../requestCode';

const switcher = (
  <Routes>
    <Route path={LOGIN} element={<Login />} />
    {/*<Route path={LOGIN_TWO_FA} element={<LoginTwoFA />} />*/}
    <Route path={REGISTER} element={<Registration />} />
    {/*<Route path={REQUEST_CODE} element={<RequestCode />} />*/}
    {/*<Route path={PASSWORD_RECOVER} element={<PasswordRecover />} />*/}
    {/*<Route path={PASSWORD_RESET} element={<PasswordReset />} />*/}

    <Route path="*" element={<Navigate to={LOGIN} />} />
  </Routes>
);

/**
 * Handles public PublicHome where user is not yet logged in.
 */
const PublicHome = (): JSX.Element => {
  return (
    <S.HomeWrapper>
      <S.SwitcherPage>{switcher}</S.SwitcherPage>
    </S.HomeWrapper>
  );
};

export default PublicHome;
