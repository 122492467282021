import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries';

export const accommodationBookingTypeFragments = {
  accommodationBookingTypes: gql`
    fragment AccommodationBookingTypes on AccommodationBookingTypes {
      items {
        ...AccommodationBookingType
      }
      paging {
        ...Paging
      }
    }
  `,
  accommodationBookingType: gql`
    fragment AccommodationBookingType on AccommodationBookingType {
      id
      name
      nameEn

      createdDate
      lastModifiedDate
    }
  `,
};

export const getAccommodationBookingType = gql`
  query getAccommodationBookingType($id: Int!) {
    getAccommodationBookingType(id: $id) {
      ...AccommodationBookingType
    }
  }
  ${accommodationBookingTypeFragments.accommodationBookingType}
`;

export const getAccommodationBookingTypes = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: AccommodationBookingTypeSearchCriteria) {
    getAccommodationBookingTypes(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...AccommodationBookingTypes
    }
  }
  ${accommodationBookingTypeFragments.accommodationBookingType}
  ${accommodationBookingTypeFragments.accommodationBookingTypes}
  ${fragments.paging}
`;
