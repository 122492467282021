import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries';

export const createUser = gql`
  mutation ($input: UserInput!) {
    createUser(input: $input) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const updateUser = gql`
  mutation ($email: String!, $input: UserInput!) {
    updateUser(email: $email, input: $input) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const deleteUser = gql`
  mutation ($id: String!) {
    deleteUser(id: $id)
  }
`;

export const updateUserLanguage = gql`
  mutation ($language: LanguageCode!) {
    updateUserLanguage(language: $language) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const updateUserSecurity = gql`
  mutation ($email: String!, $role: String!, $excludedPermissions: [String]) {
    updateUserSecurity(email: $email, role: $role, excludedPermissions: $excludedPermissions) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const requestEmailChange = gql`
  mutation ($email: String, $newEmail: String!) {
    requestEmailChange(email: $email, newEmail: $newEmail) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const changePassword = gql`
  mutation ($email: String, $currentPassword: String!, $newPassword: String!) {
    changePassword(email: $email, currentPassword: $currentPassword, newPassword: $newPassword) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const requestUserDeletion = gql`
  mutation ($email: String) {
    requestUserDeletion(email: $email)
  }
`;

export const closeUserNotifications = gql`
  mutation ($ids: [String!]!) {
    closeUserNotifications(ids: $ids)
  }
`;

export const updateUserNotificationSettings = gql`
  mutation ($email: String, $settings: [UserNotificationSettingInput!]!) {
    updateUserNotificationSettings(email: $email, settings: $settings) {
      ...UserNotificationSettings
    }
  }
  ${fragments.userNotificationSettings}
  ${fragments.userNotificationSetting}
`;

export const createInvitation = gql`
  mutation ($email: String, $role: String, $excludedPermissions: [String!]) {
    createInvitation(email: $email, role: $role, excludedPermissions: $excludedPermissions) {
      ...Invitation
    }
  }
  ${fragments.invitation}
`;

export const resendInvitation = gql`
  mutation ($code: String!) {
    resendInvitation(code: $code) {
      ...Invitation
    }
  }
  ${fragments.invitation}
`;

export const deleteInvitation = gql`
  mutation ($code: String!) {
    deleteInvitation(code: $code)
  }
`;

export const createUserEvent = gql`
  mutation ($type: UserEventType!, $clientMetadata: [String!]!, $eventMetadata: [String!]!) {
    createUserEvent(type: $type, clientMetadata: $clientMetadata, eventMetadata: $eventMetadata)
  }
`;

export const updateUserOnlineStatus = gql`
  mutation ($online: Boolean!) {
    updateUserOnlineStatus(online: $online)
  }
`;
