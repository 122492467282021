import styled from 'styled-components';

// PublicHome
export const HomeWrapper = styled.div`
  height: calc(100vh - 2rem); // - 2rem padding
  padding: ${(props) => props.theme.antd.padding}px;
  background: ${(props) => props.theme.antd.colorBgLayout};
`;

// Switcher
export const SwitcherPage = styled.div`
  height: calc(100vh - 5.25rem);
  display: flex;
`;
