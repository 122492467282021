import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries';

export const priceConfigFragments = {
  priceConfigs: gql`
    fragment PriceConfigs on PriceConfigs {
      items {
        ...PriceConfig
      }
      paging {
        ...Paging
      }
    }
  `,
  priceConfig: gql`
    fragment PriceConfig on PriceConfig {
      id
      year
      type
      amount
    }
  `,
};

export const getPriceConfig = gql`
  query getPriceConfig($id: String!) {
    getPriceConfig(id: $id) {
      ...PriceConfig
    }
  }
  ${priceConfigFragments.priceConfig}
`;

export const getPriceConfigs = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: PriceConfigSearchCriteria) {
    getPriceConfigs(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...PriceConfigs
    }
  }
  ${priceConfigFragments.priceConfigs}
  ${priceConfigFragments.priceConfig}
  ${fragments.paging}
`;
