import React, { lazy, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { HOME, LOGIN, SALES_REQUESTS } from 'utils/routingUtils';
import { useCustomisedMomentLocales } from 'utils/dateTimeUtils';
import { userHasSession } from 'utils/userUtils';
import { useTrackedSelector } from 'store/store';
import PublicHome from 'pages/public/home/PublicHome';

const UserHome = lazy(() => import('pages/private/home/UserHome'));

/**
 * Defines a Switch that covers all routes(public and for all possible user roles)
 *
 * Note: 1) the user's role and 2) whether a user is logged in define which routes are allowed to be accessed
 */
const AppBody = (): JSX.Element => {
  useCustomisedMomentLocales();

  const {
    auth: { user },
  } = useTrackedSelector();

  const hasSession = useMemo(() => userHasSession(user), [user]);

  const publicRoutes = useMemo(
    () => (
      <Routes>
        <Route path={HOME}>
          <Route index element={<Navigate to={LOGIN} />} />

          {/* routes that can happen when a user is logged in and he is not */}
          {/*<Route path={EMAIL_CONFIRMATION} element={<EmailConfirmation />} />*/}
          {/*<Route path={PROFILE_DELETION} element={<ProfileDeletion />} />*/}

          <Route path="*" element={<PublicHome />} />
        </Route>
      </Routes>
    ),
    [],
  );

  const privateRoutes = useMemo(
    () => (
      <Routes>
        <Route path={HOME}>
          <Route index element={<Navigate to={SALES_REQUESTS} />} />

          {/* routes that can happen when a user is logged in and he is not */}
          {/*<Route path={EMAIL_CONFIRMATION} element={<EmailConfirmation />} />*/}
          {/*<Route path={PROFILE_DELETION} element={<ProfileDeletion />} />*/}

          <Route path="*" element={<UserHome />} />
        </Route>
      </Routes>
    ),
    [],
  );

  return !hasSession ? publicRoutes : privateRoutes;
};

export default AppBody;
