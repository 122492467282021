import React, { FC } from 'react';
import * as S from './CustomSpin.styles';
import { SpinProps, Spin } from 'antd';

interface CustomSpinProps extends SpinProps {
  icon?: JSX.Element;
  positioningHeight?: string;
}

const CustomSpin: FC<CustomSpinProps> = ({ children, icon, positioningHeight, ...restProps }) => {
  return (
    <S.Spin style={{ height: positioningHeight }}>
      <Spin indicator={icon} {...restProps}>
        {children}
      </Spin>
    </S.Spin>
  );
};

export default CustomSpin;
