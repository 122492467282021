/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useState } from 'react';
import { UserEventType } from 'types/types.d';
import { useMutation } from '@apollo/client';
import { createUserEvent } from 'graphql/mutations';
import logger from 'utils/logger/logger';
import { useTranslation } from 'react-i18next';
import { deviceType, useDeviceData } from 'react-device-detect';

/**
 * @return callback to capture a user event and send it to the BE
 */
const useUserTracking = () => {
  const {
    i18n: { language },
  } = useTranslation();
  // @ts-ignore
  const { browser, os } = useDeviceData();

  const [trackPageView] = useMutation<{ createUserEvent: boolean }>(createUserEvent);

  const [clientMetadata, setClientMetadata] = useState<string[]>([]);

  const trackUserEvent = useCallback(
    (type: UserEventType, eventMetadata: string[] = []) => {
      if (!!clientMetadata.length) {
        trackPageView({
          variables: { type, clientMetadata, eventMetadata },
        }).catch((e) => logger.error(`Error tracking user event: ${e}`));
      }
    },
    [trackPageView, clientMetadata],
  );

  // define client metadata and geolocation
  useEffect(() => {
    const baseMetadata = [
      `platform=${deviceType}`,
      `osName=${browser.name}`,
      `osVersion=${browser.version}`,
      `deviceBrand=${os.name}`,
      `deviceModel=${os.version}`,
      `language=${language}`,
    ];
    setClientMetadata(baseMetadata);

    // API stopped working for free
    // fetch users location data
    // axios
    //   .get('https://geolocation-db.com/json/')
    //   .then((res) => {
    //     // @ts-ignore
    //     const country = res.data.country_name;
    //     // @ts-ignore
    //     const region = res.data.state;
    //     // @ts-ignore
    //     const city = res.data.city;
    //     // @ts-ignore
    //     const ip = res.data.IPv4;
    //     // @ts-ignore
    //     const latitude = res.data.latitude;
    //     // @ts-ignore
    //     const longitude = res.data.longitude;
    //     setClientMetadata(
    //       baseMetadata.concat([
    //         `country=${country}`,
    //         `region=${region}`,
    //         `city=${city}`,
    //         `ip=${ip}`,
    //         `latitude=${latitude}`,
    //         `longitude=${longitude}`,
    //       ]),
    //     );
    //   })
    //   .catch((e) => {
    //     logger.error(`Error fetching user location: ${e}`);
    //     setClientMetadata(baseMetadata);
    //   });
  }, [browser.name, browser.version, language, os.name, os.version]);

  return trackUserEvent;
};

export default useUserTracking;
