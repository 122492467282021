import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries';

export const agencyFragments = {
  agencies: gql`
    fragment Agencies on Agencies {
      items {
        ...Agency
      }
      paging {
        ...Paging
      }
    }
  `,
  agency: gql`
    fragment Agency on Agency {
      id
      code
      fullName
      email
      phoneNumber
      country
      zipCode
      city
      street
      vatId

      likeCamino
      registrationFee
      registrationFeeCommission
      courseCommission
      accommodationCommission
      commissionExceptions {
        ...AgencyCommissionException
      }

      deleted
      disabled

      createdDate
      lastModifiedDate
    }
  `,
  commissionExceptions: gql`
    fragment AgencyCommissionException on AgencyCommissionException {
      courseTypeId
      accTypeId
      accRoomId
      accFoodId
      commission

      accommodation
    }
  `,
};

export const getAgency = gql`
  query getAgency($id: Int!) {
    getAgency(id: $id) {
      ...Agency
    }
  }
  ${agencyFragments.agency}
  ${agencyFragments.commissionExceptions}
`;

export const getAgencies = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: AgencySearchCriteria) {
    getAgencies(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...Agencies
    }
  }
  ${agencyFragments.agencies}
  ${agencyFragments.agency}
  ${agencyFragments.commissionExceptions}
  ${fragments.paging}
`;
