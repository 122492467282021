import { useContext } from 'react';
import { ModalContext, ModalContextProps } from 'contextProviders/ModalProvider';
import ReactDOM from 'react-dom';

const ModalPortal = (): JSX.Element | null => {
  const { context = true, isOpened, modalContent } = useContext(ModalContext) as ModalContextProps;

  if (!context || !isOpened) {
    return null;
  }

  return ReactDOM.createPortal(modalContent, context);
};

export default ModalPortal;
