import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccommodationProviderType } from 'types/accommodation/accommodationProviderTypes';
import { HostFamily } from 'types/accommodation/hostFamilyTypes';

export type AccommodationSliceState = {
  onsiteProviders: AccommodationProviderType[];
  offsiteProviders: AccommodationProviderType[];
  hostFamilies: HostFamily[];
};

const initialState = {
  onsiteProviders: [],
  offsiteProviders: [],
  hostFamilies: [],
} as AccommodationSliceState;

const accommodationSlice = createSlice({
  name: 'accommodationSlice',
  initialState: initialState,
  reducers: {
    onsiteProvidersSet(state, action: PayloadAction<AccommodationProviderType[]>) {
      state.onsiteProviders = action.payload;
    },
    offsiteProvidersSet(state, action: PayloadAction<AccommodationProviderType[]>) {
      state.offsiteProviders = action.payload;
    },
    hostFamiliesSet(state, action: PayloadAction<HostFamily[]>) {
      state.hostFamilies = action.payload;
    },
  },
});

export const { onsiteProvidersSet, offsiteProvidersSet, hostFamiliesSet } = accommodationSlice.actions;

export default accommodationSlice.reducer;
