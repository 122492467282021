import styled from 'styled-components';
import { Card, Typography, Divider, Button, Form } from 'antd';
import Link from 'antd/lib/typography/Link';
import { BREAKPOINTS } from 'components/Breakpoints';

export const RegistrationCard: typeof Card = styled(Card)`
  padding: ${(props) => props.theme.base.paddingXXL}px;
  width: 31.25rem;
  margin: auto;

  @media (max-width: ${BREAKPOINTS.SM}px) {
    padding: ${(props) => props.theme.antd.padding}px;
  }
`;

export const TitleAndLink = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${(props) => props.theme.antd.marginXL}px;

  @media (max-width: ${BREAKPOINTS.SM}px) {
    margin-bottom: ${(props) => props.theme.antd.margin}px;
  }
`;

export const HeaderTitle = styled.span`
  font-weight: ${(props) => props.theme.antd.fontWeightStrong};
  font-size: ${(props) => props.theme.antd.fontSizeHeading3}px;
  line-height: 2rem;

  @media (max-width: ${BREAKPOINTS.SM}px) {
    font-size: ${(props) => props.theme.antd.fontSizeXL}px;
    line-height: 1.75rem;
  }
`;

export const HeaderLink: typeof Link = styled(Typography.Link)`
  align-self: flex-end;
`;

export const FormItemRegistration: typeof Form.Item = styled(Form.Item)`
  @media (max-width: ${BREAKPOINTS.SM}px) {
    margin-bottom: ${(props) => props.theme.antd.margin}px;
  }
`;

export const TermsAccepted = styled.div`
  display: flex;
  align-items: baseline;
`;

export const RegistrationDivider: typeof Divider = styled(Divider)`
  margin-top: ${(props) => props.theme.antd.marginXL}px !important;
  margin-bottom: ${(props) => props.theme.antd.marginXL}px !important;

  @media (max-width: ${BREAKPOINTS.SM}px) {
    margin-top: ${(props) => props.theme.antd.margin}px !important;
    margin-bottom: ${(props) => props.theme.antd.margin}px !important;
  }
`;

export const BtnsSocialNetworks = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.SM}px) {
    display: block;
  }
`;

export const BtnSocialNetwork: typeof Button = styled(Button)`
  @media (max-width: ${BREAKPOINTS.SM}px) {
    margin-bottom: ${(props) => props.theme.antd.margin}px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
