import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries';

export const receiptTemplateFragments = {
  receiptTemplates: gql`
    fragment ReceiptTemplates on ReceiptTemplates {
      items {
        ...ReceiptTemplate
      }
      paging {
        ...Paging
      }
    }
  `,
  receiptTemplate: gql`
    fragment ReceiptTemplate on ReceiptTemplate {
      id
      name
      bodies {
        ...Translation
      }
      deleted

      createdDate
      lastModifiedDate
    }
  `,
};

export const getReceiptTemplate = gql`
  query getReceiptTemplate($id: Int!) {
    getReceiptTemplate(id: $id) {
      ...ReceiptTemplate
    }
  }
  ${receiptTemplateFragments.receiptTemplate}
  ${fragments.translation}
`;

export const getReceiptTemplates = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: ReceiptTemplateSearchCriteria) {
    getReceiptTemplates(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...ReceiptTemplates
    }
  }
  ${receiptTemplateFragments.receiptTemplates}
  ${receiptTemplateFragments.receiptTemplate}
  ${fragments.paging}
  ${fragments.translation}
`;
