export const DEBOUNCE_TIMOUT = 500;

export const MODAL_WIDTH_EXTRA_SMALL = 480;
export const MODAL_WIDTH_SMALL = 640;
export const MODAL_WIDTH_MEDIUM = 980;
export const MODAL_WIDTH_LARGE = 1200;

export const capitalizeFirstLetter = (value: string | null): string | null => {
  if (value !== null) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  } else {
    return null;
  }
};

export const deCapitalizeFirstLetter = (value: string | null): string | null => {
  if (value !== null) {
    return value.charAt(0).toLowerCase() + value.slice(1);
  } else {
    return null;
  }
};

export const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export const websiteRegex =
  /^((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
export const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
export const bicRegex =
  /^[A-Z]{4}(00|AD|AE|AF|AG|AI|AL|AM|AO|AQ|AR|AS|AT|AU|AW|AX|AZ|BA|BB|BD|BE|BF|BG|BH|BI|BJ|BL|BM|BN|BO|BQ|BR|BS|BT|BV|BW|BY|BZ|CA|CC|CD|CF|CG|CH|CI|CK|CL|CM|CN|CO|CR|CU|CV|CW|CX|CY|CZ|DE|DJ|DK|DM|DO|DZ|EC|EE|EG|EH|ER|ES|ET|FI|FJ|FK|FM|FO|FR|GA|GB|GD|GE|GF|GG|GH|GI|GL|GM|GN|GP|GQ|GR|GS|GT|GU|GW|GY|HK|HM|HN|HR|HT|HU|ID|IE|IL|IM|IN|IO|IQ|IR|IS|IT|JE|JM|JO|JP|KE|KG|KH|KI|KM|KN|KP|KR|KW|KY|KZ|LA|LB|LC|LI|LK|LR|LS|LT|LU|LV|LY|MA|MC|MD|ME|MF|MG|MH|MK|ML|MM|MN|MO|MP|MQ|MR|MS|MT|MU|MV|MW|MX|MY|MZ|NA|NC|NE|NF|NG|NI|NL|NO|NP|NR|NU|NZ|OM|PA|PE|PF|PG|PH|PK|PL|PM|PN|PR|PS|PT|PW|PY|QA|RE|RO|RS|RU|RW|SA|SB|SC|SD|SE|SG|SH|SI|SJ|SK|SL|SM|SN|SO|SR|SS|ST|SV|SX|SY|SZ|TC|TD|TF|TG|TH|TJ|TK|TL|TM|TN|TO|TR|TT|TV|TW|TZ|UA|UG|UM|US|UY|UZ|VA|VC|VE|VG|VI|VN|VU|WF|WS|XK|YE|YT|ZA|ZM|ZW)(01|[2-9A-Z][0-9A-NP-Z])(XXX|[0-9A-WYZ]{1}[0-9A-Z]{2})?/;

// max value IBAN 34 symbols
export const ibanFormat = 'aa** **** **** **** **** **** **** **** **';

export const makeId = (len: number | undefined = 16, characters = 'abcdefghijklmnopqrstuvwxyz0123456789'): string => {
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const disemvowel = (str: string): string => (str ? str.replace(/[aeiouy]/gi, '') : '');

export const getObjectUrlParams = (obj = {}): string => {
  const params = {};
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Object.keys(obj).map((key) => (params[key] = obj[key])); //(params[disemvowel(key).toLocaleLowerCase()] = obj[key]));
  return new URLSearchParams(params).toString();
};

export const cleanUpUmlauts = (value: string): string =>
  value
    .replaceAll('ü', 'ue')
    .replaceAll('ö', 'oe')
    .replaceAll('ä', 'ae')
    .replaceAll('ß', 'ss')
    .replaceAll('Ü', 'UE')
    .replaceAll('Ö', 'OE')
    .replaceAll('Ä', 'AE')
    .replaceAll('ß', 'SS')
    .replaceAll('æ', 'ae')
    .replaceAll('ø', 'oe')
    .replaceAll('å', 'aa')
    .replaceAll('Æ', 'AE')
    .replaceAll('Ø', 'OE')
    .replaceAll('Å', 'AA');

export const joinToString = (values: (string | null | undefined)[], delimiter = ', '): string =>
  values.filter((i) => !!i?.length && i !== 'null').join(delimiter);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onlyIntegerHandler = (event: any) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const snakeCaseToCamelCase = (columnName: string): string =>
  columnName.replace(/_([a-z])/g, (_, match) => match.toUpperCase());

export const getUrlExtension = (url: string): string | undefined => {
  return url.split(/[#?]/)[0].split('.').pop()?.trim();
};
