import { gql } from '@apollo/client';
import { platformFragments } from 'graphql/platformQueries';
import { fragments } from 'graphql/queries';

export const hostFamilyFragments = {
  hostFamily: gql`
    fragment HostFamily on HostFamily {
      id
      name
      disabled

      phoneNumber
      secondPhoneNumber
      email

      address

      petsGroup
      petsDescription
      children

      food
      vegetarian

      walkingDistance
      walkingDistanceGroup
      transportDistanceInMin

      area
      comment
      desc
      descSpanish
      descByTrain
      descByBus
      descByTaxi
      descToSchool
      mapLink

      photos {
        ...File
      }

      rooms {
        ...HostFamilyRoom
      }
    }
  `,

  hostFamilyRoom: gql`
    fragment HostFamilyRoom on HostFamilyRoom {
      id
      familyId

      name
      disabled
      roomTypeId
      description
      descriptions {
        ...Translation
      }
      sizeInSqm

      ac
      bathroom
      balcony
      interior
      exterior
      hairdryer
      bedsheets
      wifi

      pricePerWeek
      providerPricePerWeek

      photos {
        ...File
      }
    }
  `,
};

export const getHostFamilies = gql`
  query ($searchCriteria: HostFamilySearchCriteria) {
    getHostFamilies(searchCriteria: $searchCriteria) {
      ...HostFamily
    }
  }
  ${hostFamilyFragments.hostFamily}
  ${hostFamilyFragments.hostFamilyRoom}
  ${fragments.translation}
  ${platformFragments.file}
`;
